import moment from 'moment'

/**
 * 20221101 -> 11.01(월), 09001100 -> 9:00~11:00 으로 변환
 * @param {*} value
 * @returns
 */
export const dateToBookingText = value => {
	// 예) 1월 1일(월)
	const bookingDate = moment(value.bookingDate)
		.locale(`${process.env.VUE_APP_I18N_LOCALE}`)
		.format('M.D(ddd)')

	if (
		value.bookingSlotTime &&
		value.bookingSlotTime.length > 0 &&
		value.bookingSlotTime !== '00000000' &&
		value.bookingSlot !== 'MC'
	) {
		const from = value.bookingSlotTime.slice(0, 4)
		const bookingSlotTimeFrom = from
			? moment(from, 'HHmm')
					.locale(`${process.env.VUE_APP_I18N_LOCALE}`)
					.format('a h:mm')
			: ''

		const to = value.bookingSlotTime.slice(4)
		const bookingSlotTimeTo = to
			? moment(to, 'HHmm')
					.locale(`${process.env.VUE_APP_I18N_LOCALE}`)
					.format('h:mm')
			: ''

		let bookingText
		if (from === '1600' && to === '0000') {
			bookingText = `${bookingDate} ${bookingSlotTimeFrom} 이후`
		} else {
			bookingText = `${bookingDate} ${bookingSlotTimeFrom}~${bookingSlotTimeTo}`
		}

		return bookingText
	} else if (value.bookingSlot === 'MC') {
		return `${bookingDate} 방문기사와 시간 협의`
	} else {
		return bookingDate
	}
}
