<template>
	<div class="cw-app-wrapper">
		<div class="cw-app-container edit">
			<p class="txt_body1">
				안녕하세요. 코웨이㈜입니다.
				<br />
				{{ name }} 고객님의 주문이 접수되었습니다. <br />
				아래 주문 내용을 확인 후 다음을 눌러 서명을 진행해 주세요.
			</p>
			<ul class="info_list">
				<li>
					<dt>고객명</dt>
					<dd>{{ name }}</dd>
				</li>
				<li>
					<dt>전화번호</dt>
					<dd>{{ $options.filters.phone(phone) }}</dd>
				</li>
				<li>
					<dt>주문번호</dt>
					<dd>{{ orderNo }}</dd>
				</li>
			</ul>
			<div class="inner_box my-0">
				<div class="summay_list ">
					<dl class="bg_gray px-6">
						<dt>
							주문 요약
						</dt>
						<dd class="primary--text">
							총
							<strong>{{ orderList.length - orderCancelListCnt }}</strong
							>개
						</dd>
					</dl>
				</div>
				<div class="summay_list  px-6">
					<template v-for="(sum, i) in groupbySumList">
						<dl :key="i">
							<dt>
								{{ getPrdName(sum.code) }}
							</dt>
							<dd>{{ sum.sale }} / {{ sum.cnt }}개</dd>
						</dl>
					</template>
					<dl class="gray_row2">
						<dt>
							구분
						</dt>
						<dd>납부금액 / 정기결제(매월)</dd>
					</dl>
					<dl class="no_bor">
						<dt>
							금액
						</dt>
						<dd>
							<span class="col-6">{{ sumAmt.PRICE | comma }}원/</span>
							<span class="col-6">{{ sumAmt.MONTH_AMT | comma }}원</span>
						</dd>
					</dl>
					<dl class="no_bor">
						<dt class="primary--text">
							할인
						</dt>
						<dd class="primary--text">
							<span class="col-6">-{{ sumAmt.DC_GOODS_AMT | comma }}원/</span>
							<span class="col-6">-{{ sumAmt.DC_MONTH_AMT | comma }}원</span>
						</dd>
					</dl>
					<dl class="">
						<dt class="bold">
							합계
						</dt>
						<dd class="bold">
							<span class="col-6">{{ sumAmt.GOODS_AMT | comma }}원/</span>
							<span class="col-6">{{ sumAmt.REAL_MONTH_AMT | comma }}원</span>
						</dd>
					</dl>
					<div class="infor_more pl-0">
						납부금액 : 등록비, 일시불 구매, 설치비, 계약금
					</div>
					<div class="infor_more pl-0">
						정기결제(매월) : 렌탈, 자체할부, 멤버십 비용
					</div>
				</div>
			</div>
			<div class="u-agree-box">
				<div class="rules">
					<div class="all">
						<ACheckbox
							class="bold_check_2"
							:checked="!isDisableBtn"
							@change="changeCheckedAll($event)"
							:label="'모두 확인했습니다.'"
						/>
					</div>
					<ul class="list">
						<template v-for="(agree, i) in agreeList">
							<li :key="i">
								<ACheckbox
									class="item bold"
									:checked="agree.checked"
									@checked="agreeChk($event, agreeList, i)"
									:label="agree.cateName"
								/>
								<v-btn
									class="file"
									@click="openPdfPop(agree)"
									download
									text
									:ripple="false"
									depressed
								></v-btn>
							</li>
						</template>
						<li class="cut">
							<ACheckbox
								class="item txt"
								:checked="rentalAgr01"
								@checked="rentalChk($event, 'rentalAgr01')"
								:label="
									'렌탈 등록비(가입비), 회수비, 렌탈료, 렌탈 경과 혜택 규정을 확인 숙지하였습니다. (약관 제 2, 5, 12조 참조) \n ※ 회수비:정수기4만원,청정기3만원,비데3만원,연수기3만원,매트리스5만원,의류청정기5만원,전기레인지4만원'
								"
							/>
						</li>
						<li>
							<ACheckbox
								class="item txt"
								:checked="rentalAgr02"
								@checked="rentalChk($event, 'rentalAgr02')"
								:label="
									'청약철회, 해지, 위약금 규정을 확인 및 숙지하였습니다. (약관 제3, 9, 10조 참조)'
								"
							/>
						</li>
						<li>
							<ACheckbox
								class="item txt"
								:checked="rentalAgr03"
								@checked="rentalChk($event, 'rentalAgr03')"
								:label="
									'별매상품은 렌탈 제외로 고객의 별도 구매 대상입니다. 단, 고객이 모터 펌프(월 2,000원), 자동물내림 기능(월800원)을 요청하면 렌탈료에 추가 청구됩니다.'
								"
							/>
						</li>
						<li>
							<ACheckbox
								class="item txt"
								:checked="rentalAgr04"
								@checked="rentalChk($event, 'rentalAgr04')"
								:label="
									'본인이 납부하여야 할 요금에 대해 CMS 출금이체 서비스를 이용하는데 동의하며, 별도의 통지 없이 위의 본인의 지정 출금계좌(카드번호)에서 지정출금(결제)일에 출금(결제)되는 것에 동의합니다.'
								"
							/>
						</li>
					</ul>
				</div>
			</div>
			<v-expansion-panels focusable class="type_01">
				<v-expansion-panel class="accodion_select">
					<v-expansion-panel-header class=""
						><h3 class="sub_tit_type_01 type_01">주문내역 상세 보기</h3>
						<p class="selected"></p
					></v-expansion-panel-header>
					<v-expansion-panel-content class="bg_white">
						<div class="summay_list mt-4">
							<div class="infor_more">
								기기별로 주문번호가 부여됩니다.
							</div>
						</div>
						<template v-for="(ord, i) in orderList">
							<div class="px-6" :key="i">
								<h3 class="mb-4 mt-4">
									<span>주문 {{ i + 1 }}. </span>
									<strong
										:class="ord.orderCancelYn ? 'ft-orange' : 'primary--text'"
									>
										{{ ord.GOODS_NM ? ord.GOODS_NM : deliveryInfos[i].prodNm }}
										{{
											getPrdName(ord.PRODH9) === '기타'
												? getPrdName(deliveryInfos[i].PRODH9)
												: getPrdName(ord.PRODH9)
										}}
									</strong>
								</h3>
								<div class="wrap_list_detail ">
									<dl>
										<dt>주문번호</dt>
										<dd>{{ ord.ORDER_NO }}</dd>
									</dl>
									<dl class="list_detail">
										<dt>구매방식</dt>
										<dd>{{ ord.E_SALE_GUBUN }}</dd>
									</dl>
									<dl
										class="list_detail"
										v-if="ord.GOODS_AMT > 0 && !ord.orderCancelYn"
									>
										<dt>수납정보</dt>
										<dd>
											{{ ord.GOODS_AMT | comma }} 원 <br />{{
												ord.T_A0401_PAYMENT_LIST.filter(x => x.GUBUN === '2')[0]
													? ord.T_A0401_PAYMENT_LIST.filter(
															x => x.GUBUN === '2'
													  )[0].CARDCOM_NM
													: ''
											}}
											{{
												getMaskedAccNo(
													ord.T_A0401_PAYMENT_LIST.filter(
														x => x.GUBUN === '2'
													)[0]
														? ord.T_A0401_PAYMENT_LIST.filter(
																x => x.GUBUN === '2'
														  )[0].CARD_NO
														: ''
												)
											}}
										</dd>
									</dl>

									<dl
										class="list_detail"
										v-if="ord.MONTH_AMT > 0 && !ord.orderCancelYn"
									>
										<dt>정기결제</dt>
										<dd>
											{{ ord.MONTH_AMT | comma }} 원/월 <br />{{
												ord.CARDCOM_NM ? ord.CARDCOM_NM : ''
											}}
											{{ ord.CARD_NO ? getMaskedAccNo(ord.CARD_NO) : '' }}
										</dd>
									</dl>
									<dl class="list_detail" v-if="!ord.orderCancelYn">
										<dt>설치주소</dt>
										<dd>{{ ord.CITY1 }} {{ ord.STREET }}</dd>
									</dl>
									<dl class="list_detail" v-if="!ord.orderCancelYn">
										<dt>설치일시</dt>
										<dd v-if="isPreContract" class="ft-blue">
											{{
												deliveryInfos[i].workerType !== 'EXPRESS'
													? ord.E_YYYY_MM + ' 이후 설치 가능'
													: '택배 배송'
											}}
										</dd>
										<dd v-else>
											{{
												deliveryInfos[i].workerType !== 'EXPRESS'
													? dateToBookingText({
															bookingDate: deliveryInfos[i].bookingDate,
															bookingSlot: deliveryInfos[i].bookingSlot,
															bookingSlotTime: deliveryInfos[i].bookingSlotTime
													  })
													: '택배 배송'
											}}
										</dd>
									</dl>
									<dl v-if="ord.orderCancelYn">
										<dt>주문상태</dt>
										<dd>주문삭제</dd>
									</dl>
								</div>
							</div>
						</template>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
			<div class="bottom_box">
				<v-btn
					color="primary"
					class="btn_col2 col-12"
					@click="signature()"
					:disabled="isDisableBtn"
				>
					전자계약서 보기
				</v-btn>
			</div>
		</div>
		<CustInfoDialog ref="custPop" />
	</div>
</template>
<script>
/**
 * 전자서명 진입
 * @description 전자서명 진입 페이지
 *              전자서명 여부를 체크하고 결과에 따라 화면을 이동한다.
 *              주문확정, 주문삭제, 전자서명
 */
import {
	chkAuthExpired,
	fetchECustByCheckOrderNo,
	fetchEAgreeHistory,
	fetchEContract,
	fetchCreateEcCookie
} from '@/apis/order.ec.api'
import { paymentInfo } from '@/apis/order.recp.api'
import { dateToBookingText } from '@/utils/schedule.js'
import {
	checkingStatus,
	fetchWebOrderInfoById,
	postWebOrderInfoById
} from '@/apis/order.status.api'
import { fetchCustSetup } from '@/apis/order.verify.api'
import { fetchDecrypedPassVerifiedCust } from '@/apis/order.verify.api'
import filters from '@/mixins/filters'
import {
	getPrdName,
	getPdfLinkType,
	getMaskedAccNo,
	groupbyOrderInfo,
	getAgreeCateName
} from '@/utils/ec'
import { notEqualIgnoreMasking, isParameterEmpty } from '@/utils/common'

export default {
	mixins: [filters],
	data() {
		return {
			codyPhoneNo: '',
			name: '',
			phone: '',
			orderNo: '',
			validDate: '',
			regulRecpType: '',
			recpType: '',
			setupAddress: '',
			orderCancelListCnt: 0,
			sumAmt: [],
			sumList: [],
			orderList: [],
			groupbySumList: [],
			orderStatusList: [],
			payRecp: {
				AF_BANK_CD_NM: '',
				AF_CARD_NO: ''
			},
			webOrderSum: [],
			recpInfos: {},
			dpParams: {}, // 복합결제 가상계좌
			checkedAll: false,
			agreeList: [], // 약관 목록
			deliveryInfos: [],
			orderCancelYn: false,
			rentalAgr01: false,
			rentalAgr02: false,
			rentalAgr03: false,
			rentalAgr04: false,
			isPreContract: false
		}
	},
	computed: {
		isDisableBtn() {
			return !this.isCheckedAll()
		}
	},
	async mounted() {
		// PASS 인증 후 랜딩
		const { retInfo } = this.$route.query
		const { pass72Res } = this.$route.params // 72시간 내 인증 데이터

		let custPassInfo = {}
		if (retInfo) {
			const orderId = sessionStorage.getItem('orderId')
			if (this.isParameterEmpty(orderId)) return
			const passRes = await fetchDecrypedPassVerifiedCust({ retInfo, orderId })
			custPassInfo = passRes.resultObject
		} else if (pass72Res) {
			custPassInfo = pass72Res
		} else {
			this.$log('else has no pass params')
			// return
		}

		// /v1/06/03 인증페이지 만료여부 확인 1) 페이지 진입시, 2) [전자서명하기] 클릭시
		const userKey = sessionStorage.getItem('userKey')
		let res = {}
		if (userKey) {
			res = await chkAuthExpired({ userKey })
		} else {
			res.resultObject = {}
			res.resultObject.valid = true
		}
		if (res.resultObject.valid) {
			this.validDate = res.resultObject.expireDateTime
			let info = {}

			info = res.resultObject.orderInfo
			const json = JSON.parse(info.orderInfoJson)

			const orderList = json.orderInfoResult

			this.deliveryInfos = json.deliveryInfos

			const mobOrderNo = info.id
			if (this.isParameterEmpty(mobOrderNo)) return
			// 전자서명 이후 모바일주문번호를 사용하기 위해 세션스토리지에 저장
			sessionStorage.setItem('mobOrderNo', mobOrderNo)

			if (orderList) {
				// 서명 여부를 체크하고 상태에 따라 페이지 이동한다.
				fetchECustByCheckOrderNo(orderList[0].ORDER_NO)
					.then(custRes => {
						const { E_STATUS } = custRes.resultObject.data // 서명상태
						if (E_STATUS === '3') {
							// 서명완료
							location.href = `${process.env.VUE_APP_FRT_URL}/order/ec/cust/complete`
						} else if (E_STATUS === '4') {
							// 주문삭제
							location.href = `${process.env.VUE_APP_FRT_URL}/order/ec/cust/signaturecancel`
						}
					})
					.catch(error => {
						this.$alert({ message: error })
					})
			}

			if (info) {
				const json = JSON.parse(info.orderInfoJson)
				this.webOrderSum = json.PAY_SUMMARY_AMT

				this.$log('pass: ', custPassInfo.name, custPassInfo.cellNo)
				this.$log('cust: ', json.customer.name, json.customer.phone)

				if (
					(custPassInfo.name === json.customer.name &&
						custPassInfo.cellNo === json.customer.phone) ||
					!userKey
				) {
					// store 값 세팅
					this.$store.dispatch('common/setUserInfo', {
						codyNo: info.codyNo,
						codyPhoneNo: info.codyPhoneNo
					})

					this.$store.dispatch('verify/setMobOrderNo', info.id)
					this.$store.dispatch('verify/setCustomer', json.customer)
					this.$store.dispatch('pay/saveRecpAmt', json.RECP_AMT) // TODO 수납금액
					// 렌탈 할인금액
					this.$store.dispatch(
						'pay/saveMonthAmtDc',
						json.MONTH_AMT_DC !== undefined ? json.MONTH_AMT_DC : 0
					)
					this.$store.dispatch('inst/setOrderInfoResult', json.orderInfoResult)

					this.isPreContract =
						this.$store.state['verify'].customer.orderType === 'C'
					if (json.recp) {
						this.$store.dispatch('recp/setPayRecp', json.recp.payRecp)
						this.$store.dispatch('recp/setRecpInfos', json.recp.recpInfos)

						this.recpInfos = this.$store.getters['recp/recpInfos']
						if (this.recpInfos.mthd === '90') {
							this.$store.dispatch('recp/setDpParams', json.dpParams)
							this.dpParams = this.$store.getters['recp/dpParams']
						}

						this.payRecp = this.$store.getters['pay/paymentOrders']
						if (!this.payRecp || Object.keys(this.payRecp).length === 0) {
							this.payRecp = {
								AF_BANK_CD_NM: '',
								AF_CARD_NO: ''
							}
							if (this.recpInfos.mthd === '16') {
								const dpParams = this.$store.getters['recp/dpParams']
								if (dpParams && Object.keys(dpParams).length > 0) {
									const acc = dpParams.account.split(' ')
									if (acc && acc.length > 1) {
										this.payRecp = {
											AF_BANK_CD_NM: acc[0],
											AF_CARD_NO: acc[1]
										}
									}
								}
							}
						}
					}

					// 화면 값 세팅
					this.codyPhoneNo = info.codyPhoneNo
					this.phone = this.$store.getters['verify/custPhone']
					let customerType = (this.name = this.$store.getters[
						'verify/customerType'
					])

					if (customerType === 'L') {
						this.name = this.$store.getters['verify/bizName']
					} else {
						this.name = this.$store.getters['verify/custName']
					}

					const params = {
						mobOrderNo: this.$store.getters['verify/mobOrderNo']
					}

					const res = await fetchEContract(params)
					this.sumAmt = res.resultObject.data[0].sumAmt
					this.orderList = res.resultObject.data[0].setup
					this.sumList = res.resultObject.data[0].sum

					const validOrdNoList = []
					this.sumList.forEach(x => {
						this.orderList.some(y => {
							if (
								x.div === y.PRODH9 &&
								!validOrdNoList.toString().includes(y.ORDER_NO)
							) {
								x.orderNo = y.ORDER_NO
								validOrdNoList.push(y.ORDER_NO)
								return true
							}
						})
					})

					//주문상태조회
					await this.createOrderStatusList()
					// 모든 주문 취소 여부 체크
					const allCanceledOrder = await this.allCanceledOrderChkAndUpdate()
					if (allCanceledOrder) {
						const params = {
							title: '주문이 삭제된 상태입니다.',
							msg: '자세한 내용은 담당 판매인에게 연락 바랍니다.'
						}
						this.setGuideMessage(params.title, params.msg)
						return
					}

					// 주문요약 셋팅
					this.groupbySumList = await groupbyOrderInfo(
						this.sumList,
						this.orderNo
					)

					//삭제된 주문 납부금액, 할인액 차감

					// const webOrderSum = json.PAY_SUMMARY_AMT

					//약관
					this.orderList.forEach(v => {
						if (!v.orderCancelYn) {
							let contractId = v.CONTRACT_ID
							let agree = {}
							agree.checked = false
							agree.sellType = contractId === '1' ? '일시불' : '렌탈'
							agree.cateName = this.getAgreeCateName(v.CONTRACT_TYPE)
							agree.label = `${agree.cateName} ${agree.sellType} 약관`
							agree.pdfLinkType = v.CONTRACT_TYPE
							const listStr = JSON.stringify(this.agreeList)
							if (listStr.indexOf(JSON.stringify(agree)) === -1) {
								this.agreeList.push(agree)
							}
						}
					})

					if (this.orderList) {
						this.validDate = this.orderList[0].EXPIRE
						if (this.orderList.length > 1) {
							this.regulRecpType = `${
								this.orderList[0].CARDCOM_NM !== ''
									? this.orderList[0].CARDCOM_NM
									: this.payRecp.AF_BANK_CD_NM
							} ${
								this.orderList[0].CARD_NO
									? this.getMaskedAccNo(this.orderList[0].CARD_NO)
									: this.getMaskedAccNo(this.payRecp.CARD_NO)
							} 외 ${this.orderList.length - 1}건`
							this.setupAddress = `${this.orderList[0].CITY1} ${
								this.orderList[0].STREET
							} 외 ${this.orderList.length - 1}곳`
							this.recpType = `${
								this.orderList[0].T_A0401_PAYMENT_LIST.filter(
									x => x.GUBUN === '2'
								)[0]?.CARDCOM_NM
							} ${this.getMaskedAccNo(
								this.orderList[0].T_A0401_PAYMENT_LIST.filter(
									x => x.GUBUN === '2'
								)[0]?.CARD_NO
							)}`
						} else {
							this.$log('---', this.payRecp)
							this.regulRecpType = `${
								this.orderList[0].CARDCOM_NM !== ''
									? this.orderList[0].CARDCOM_NM
									: this.payRecp.AF_BANK_CD_NM
							} ${
								this.orderList[0].CARD_NO
									? this.getMaskedAccNo(this.orderList[0].CARD_NO)
									: this.getMaskedAccNo(this.payRecp.CARD_NO)
							}`
							this.setupAddress = `${this.orderList[0].CITY1} ${this.orderList[0].STREET}`
							this.recpType = `${
								this.orderList[0].T_A0401_PAYMENT_LIST.filter(
									x => x.GUBUN === '2'
								)[0]?.CARDCOM_NM
							} ${this.getMaskedAccNo(
								this.orderList[0].T_A0401_PAYMENT_LIST.filter(
									x => x.GUBUN === '2'
								)[0]?.CARD_NO
							)}`
						}
					}

					// 고객입력 정보와 SAP정보 비교
					await this.checkCustInfo(info, params)
				} else {
					this.$log('PASS 인증 고객정보와 계약확인 고객정보 불일치')
				}
			}
		} else {
			this.$router.push({ name: 'ec-cust-expired' })
		}
	},
	methods: {
		getPrdName,
		getAgreeCateName,
		getPdfLinkType,
		getMaskedAccNo,
		dateToBookingText,
		isParameterEmpty,
		agreeChk(val, agreeList, i) {
			agreeList[i].checked = val
		},
		rentalChk(val, rental) {
			this.$log(val)
			this.rental = val
			this.$log('rental', this.rental, rental)
			if (rental === 'rentalAgr01') {
				this.rentalAgr01 = val
			} else if (rental === 'rentalAgr02') {
				this.rentalAgr02 = val
			} else if (rental === 'rentalAgr03') {
				this.rentalAgr03 = val
			} else if (rental === 'rentalAgr04') {
				this.rentalAgr04 = val
			}
		},
		// 상품금액 수납 계산
		displayPrdRecvAmt(prdAmt) {
			let sum = 0
			let result = ''
			let count = 0

			prdAmt.forEach(v => {
				if (v.recvAmt !== '') {
					sum += Number(v.recvAmt)
					count += 1
				}
			})

			if (count === 0) {
				result = ''
				sum = ''
			} else {
				result = `수납 ${this.$options.filters.comma(sum)}원`
			}

			return { string: result, number: sum }
		},
		// 상품금액 월 금액 계산
		displayPrdMonthAmt(prdAmt) {
			let sum = 0
			let result = ''
			let count = 0

			prdAmt.forEach(v => {
				if (v.monthAmt !== '') {
					sum += Number(v.monthAmt)
					count += 1
				}
			})

			if (count === 0) {
				result = ''
				sum = ''
			} else {
				result = `${this.$options.filters.comma(sum)}원/월`
			}

			return { string: result, number: sum }
		},

		// 상품금액 월 금액 계산(선납 할인 계산시, 분납 등록비 제외)
		displayPrepayMonthAmt(p) {
			let sum = 0
			let result = ''
			let count = 0

			// 할인금액
			const saleMonthAmt = this.displaySaleMonthAmt(p.saleInfo.saleAmt).number
			const prdAmt = p.saleInfo.prdAmt
			prdAmt.forEach(v => {
				if (v.monthAmt !== '') {
					sum += Number(v.monthAmt)
					count += 1
				}
			})

			if (count === 0) {
				result = ''
				sum = ''
			} else {
				result = sum - saleMonthAmt
			}

			return result
		},

		// 할인금액 수납 금액 계산
		displaySaleRecvAmt(prdAmt) {
			let sum = 0
			let result = ''
			let numCount = 0
			let strCount = 0
			let value = ''

			prdAmt.forEach(v => {
				if (v.recvAmt !== '' && v.type === 'number') {
					sum += Number(v.recvAmt)
					numCount += 1
				} else if (v.recvAmt !== '' && v.type === 'string') {
					strCount += 1
					if (v.recvAmt === '별도 혜택') {
						value = '별도 혜택'
					}
				}
			})

			if (numCount === 0 && strCount === 0) {
				result = ''
				sum = ''
			} else if (numCount === 0 && strCount > 0) {
				result = value
				sum = ''
			} else if (numCount > 0 && sum === 0) {
				result = `0원`
			} else {
				result = `수납 -${this.$options.filters.comma(sum)}원`
			}

			return { string: result, number: sum }
		},

		// 할인금액 월 금액 계산
		displaySaleMonthAmt(saleAmt) {
			let sum = 0
			let result = ''
			let numCount = 0
			let strCount = 0
			let value = ''

			saleAmt.forEach(v => {
				if (v.monthAmt !== '' && v.type === 'number') {
					sum += Number(v.monthAmt)
					numCount += 1
				} else if (v.monthAmt !== '' && v.type === 'string') {
					strCount += 1
					if (v.monthAmt === '별도 혜택') {
						value = '별도 혜택'
					}
				}
			})

			if (numCount === 0 && strCount === 0) {
				result = ''
				sum = ''
			} else if (numCount === 0 && strCount > 0) {
				result = value
				sum = ''
			} else if (numCount > 0 && sum === 0) {
				result = `0원`
			} else {
				result = `-${this.$options.filters.comma(sum)}원/월`
			}

			return { string: result, number: sum }
		},
		openPdfPop(agree) {
			let link = ''
			let item = agree.pdfLinkType
			switch (item) {
				case 'LVEE':
					{
						link = `https://files.sorder.coway.com/terms/${item}.pdf`
					}
					break
				case 'LCEE':
					{
						link = `https://files.sorder.coway.com/terms/${item}.pdf`
					}
					break
				case 'RVEE':
					{
						link = `https://files.sorder.coway.com/terms/${item}.pdf`
					}
					break
				case 'RCEE':
					{
						link = `https://files.sorder.coway.com/terms/${item}.pdf`
					}
					break
				case 'LVMT':
					{
						link = `https://files.sorder.coway.com/terms/${item}.pdf`
					}
					break
				case 'LCMT':
					{
						link = `https://files.sorder.coway.com/terms/${item}.pdf`
					}
					break
				case 'RVMT':
					{
						link = `https://files.sorder.coway.com/terms/${item}.pdf`
					}
					break
				case 'RCMT':
					{
						link = `https://files.sorder.coway.com/terms/${item}.pdf`
					}
					break
				case 'RVCP':
					{
						link = `https://files.sorder.coway.com/terms/${item}.pdf`
					}
					break
				case 'RCCP':
					{
						link = `https://files.sorder.coway.com/terms/${item}.pdf`
					}
					break
				case 'LCHE':
					{
						link = `https://files.sorder.coway.com/terms/${item}.pdf`
					}
					break
			}
			window.open(link)
		},
		isCheckedAll() {
			let count = 0
			this.agreeList.forEach(agree => {
				if (
					agree.checked &&
					this.rentalAgr01 &&
					this.rentalAgr02 &&
					this.rentalAgr03 &&
					this.rentalAgr04
				) {
					count++
				}
			})

			if (count === this.agreeList.length) {
				return true
			} else {
				return false
			}
		},
		getCancelOrdPrdNm(name) {
			let prdNm = ''
			this.products = this.$store.getters['pay/payProducts']
			this.products.forEach(v => {
				if (v.prd.prod_nm === name) {
					prdNm = v.options.prdha
				}
			})
			return prdNm
		},
		changeChecked(value, i) {
			if (value) {
				if (!this.isCheckedAll()) this.checkedAll = this.agreeList[i].checked
			} else {
				this.checkedAll = value
			}
		},
		changeCheckedAll(value) {
			this.agreeList.forEach((agree, i) => {
				this.$log('this.agreeList[i]', this.agreeList[i])
				this.$set(this.agreeList[i], 'checked', value)
			})
			if (value === true) {
				this.rentalAgr01 = true
				this.rentalAgr02 = true
				this.rentalAgr03 = true
				this.rentalAgr04 = true
			} else {
				this.rentalAgr01 = false
				this.rentalAgr02 = false
				this.rentalAgr03 = false
				this.rentalAgr04 = false
			}
			this.$forceUpdate()
		},

		async signature() {
			const mobOrderNo = this.$store.getters['verify/mobOrderNo']
			if (this.isParameterEmpty(mobOrderNo)) return
			//주문상태조회
			await this.createOrderStatusList()
			// 모든 주문 취소 여부 체크
			const allCanceledOrder = await this.allCanceledOrderChkAndUpdate()
			if (allCanceledOrder) {
				const params = {
					title: '주문이 삭제된 상태입니다.',
					msg: '자세한 내용은 담당 판매인에게 연락 바랍니다.'
				}
				this.setGuideMessage(params.title, params.msg)
				return
			}

			this.$log('agreeList', this.agreeList)
			let param = {
				mobOrderNo: this.$store.getters['verify/mobOrderNo'],
				systemType: 'M',
				lceeYn:
					this.agreeList.findIndex(x => x.pdfLinkType === 'LCEE') === -1
						? 'N'
						: 'Y',
				rceeYn:
					this.agreeList.findIndex(x => x.pdfLinkType === 'RCEE') === -1
						? 'N'
						: 'Y',
				lcmtYn:
					this.agreeList.findIndex(x => x.pdfLinkType === 'LCMT') === -1
						? 'N'
						: 'Y',
				rcmtYn:
					this.agreeList.findIndex(x => x.pdfLinkType === 'RCMT') === -1
						? 'N'
						: 'Y',
				rccpYn:
					this.agreeList.findIndex(x => x.pdfLinkType === 'RCCP') === -1
						? 'N'
						: 'Y',
				lcheYn:
					this.agreeList.findIndex(x => x.pdfLinkType === 'LCHE') === -1
						? 'N'
						: 'Y',
				rentalAgr01: this.rentalAgr01 ? 'Y' : 'N',
				rentalAgr02: this.rentalAgr02 ? 'Y' : 'N',
				rentalAgr03: this.rentalAgr03 ? 'Y' : 'N',
				rentalAgr04: this.rentalAgr04 ? 'Y' : 'N'
			}
			this.$log(param)
			this.$log('param---', param)
			await fetchEAgreeHistory(param.mobOrderNo, { ...param })

			const userKey = sessionStorage.getItem('userKey')
			let res = {}
			if (userKey) {
				res = await chkAuthExpired({ userKey })
			} else {
				res.resultObject = {}
				res.resultObject.valid = true
			}

			if (res.resultObject.valid) {
				const mobOrderNo = this.$store.getters['verify/mobOrderNo']

				if (this.orderList) {
					fetchCreateEcCookie({
						webOrderNo: mobOrderNo,
						phoneNumber: this.phone
					})
						.then(res => {
							const { eMobOrderNo } = res.resultObject
							location.replace(
								`${process.env.VUE_APP_NEW_SIGN_URL}/smarteform/reqMain/requestService.jcg?zsndNo=${eMobOrderNo}&sysId=M&dmId=${process.env.VUE_APP_DMID}`
							)
						})
						.catch(() => {
							this.$alert({
								message:
									'일시적인 통신 장애로 서비스에 접속할 수 없습니다. 문제가 계속된다면, 담당자에게 문의해 주세요.'
							})
						})
				}
			} else {
				const altRes = await this.$alert({
					message:
						'인증시간이 만료됐습니다.<br />담당코디에게 연락하시겠습니까?',
					okText: '전화걸기',
					cancelText: '취소'
				})
				if (altRes) {
					location.href = `tel:${this.codyPhoneNo}`
				}
			}
		},
		async checkCustInfo(info, params) {
			// PAY_TYPE (R: 정기결제, O: 수납), CONTRACT_ID (1: 일시불, 2:렌탈)
			// 결제정보 체크
			const payInfo = (
				await paymentInfo(params.mobOrderNo, { zwebRecp: '04' })
			).resultObject.data.PAYMENT_LIST.filter(x => x.MAIN_PAYMENT)

			if (payInfo.length > 0) {
				// 수납결제
				const sum = JSON.parse(info.orderInfoJson)
				const recpInfo = sum.recp.recpParams

				if (sum.PAY_SUMMARY_AMT.totalSumOfRecpAmt > 0 && recpInfo) {
					const pay = payInfo.filter(
						pay => pay.PAY_TYPE === 'O' && pay.AF_CARD_NO.length > 0
					)[0]

					// AF_CARD_NO 값이 없는 경우 비교 X
					if (pay) {
						if (
							notEqualIgnoreMasking(
								pay.AF_CARD_NO,
								recpInfo.cardn ?? recpInfo.suacc
							)
						) {
							this.$log('고객입력 수납결제 불일치')
							this.$refs.custPop.open()
							return
						}
					}
				}

				// 정기결제 (일시불인 경우 비교 X)
				if (sum.PAY_SUMMARY_AMT.totalSumOfMonthAmt > 0) {
					const pay = payInfo.filter(
						pay => pay.PAY_TYPE === 'R' && pay.AF_CARD_NO.length > 0
					)[0]
					const orderList = this.orderList.filter(
						x => x.CONTRACT_ID === '2' && x.CARD_NO.length > 0
					)

					if (
						orderList.findIndex(x =>
							notEqualIgnoreMasking(pay.AF_CARD_NO, x.CARD_NO)
						) > -1
					) {
						this.$log('고객입력 정기결제 불일치')
						this.$refs.custPop.open()
						return
					}
				}
			}

			// 설치처정보 체크
			const setupInfo = (await fetchCustSetup(params.mobOrderNo)).resultObject
				.data.setup
			if (setupInfo) {
				if (
					this.orderList.findIndex(x =>
						notEqualIgnoreMasking(setupInfo.telNumber, x.TEL_NUMBER)
					) > -1
				) {
					this.$log('고객입력 휴대폰번호 불일치')
					this.$refs.custPop.open()
					return
				}

				if (
					this.orderList.filter(
						x =>
							x.CITY1.trim() === setupInfo.addr1.trim() &&
							x.STREET.trim() === setupInfo.addr2.trim()
					).length !== this.orderList.length
				) {
					this.$log('고객입력 주소 불일치')
					this.$refs.custPop.open()
					return
				}
			}
		},
		async createOrderStatusList() {
			const space = ', '
			const orderNoList = []
			for (const order of this.orderList) {
				orderNoList.push(order.ORDER_NO)
			}

			const response = await checkingStatus({
				saleCd: this.$store.getters['common/codyNo'],
				kunnr: this.$store.getters['verify/custNo'],
				orderNos: orderNoList
			})
			this.orderStatusList = response.resultObject.T_ORDER_STATUS
			const orderableList = []
			this.orderStatusList.forEach((v, i) => {
				if (v.ORDER_STATUS === '203') {
					this.orderList[i].orderCancelYn = true
					this.orderCancelListCnt += 1
				} else {
					orderableList.push(v.ORDER_NO)
				}
			})
			this.orderNo = orderableList.toString().replace(',', space)
		},
		async allCanceledOrderChkAndUpdate() {
			const cancelYn = this.orderStatusList.every(v => v.ORDER_STATUS === '203')
			this.$log('all order status cancel : ', cancelYn)
			if (cancelYn) {
				const mobOrderNo = this.$store.getters['verify/mobOrderNo']
				const res = await fetchWebOrderInfoById(mobOrderNo)
				const webParams = res.resultObject
				webParams.statusCode = '203'

				await postWebOrderInfoById(mobOrderNo, webParams)
			}

			return cancelYn
		},
		// 가이드 화면으로 이동
		setGuideMessage(title, message) {
			const params = {
				title: title,
				msg: message
			}
			this.$store.dispatch('common/setGuideData', params)
			this.$router.push({ name: 'guide' })
		}
	}
}
</script>
